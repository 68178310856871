.esri-ui-top-right {
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;
}

.esri-ui-top-right .esri-component {
  margin-left: 30px;
}

.esri-ui-corner .esri-component {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.esri-component.esri-search {
  width: 255px;
}

.esri-ui-bottom-right {
  flex-flow: column;
  height: calc(100% - 76px);
  justify-content: space-between;
  align-items: flex-end;
}

.esri-sketch,
.esri-search,
.esri-zoom {
  border-radius: 10px;
}

/*.esri-sketch button {*/
/*  background: "transparent";*/
/*}*/

.esri-sketch__section {
  padding: 0 6px;
  margin: 5px 0;
}

.esri-sketch__info-panel {
  border-radius: 0px 0px 10px 10px;
}

.esri-sketch__feature-count-badge {
  border-bottom: none;
}

.esri-search {
  height: 42px;
}

.esri-search__input-container {
  margin-left: 6px;
}

.esri-search__suggestions-menu {
  top: calc(100% - 3px);
  border-radius: 0px 0px 10px 10px;
  box-shadow: none;
}

.esri-input:focus {
  outline: none;
}

.esri-zoom .esri-widget--button:first-child {
  border-radius: 10px 10px 0 0;
  margin: 4px 2px 0;
}

.esri-zoom .esri-widget--button:last-child {
  border-radius: 0 0 10px 10px;
  margin: 0 2px 4px;
}

.esri-zoom .esri-widget--button:hover {
  background-color: transparent;
}

.esri-zoom .esri-widget--button:focus {
  outline: none;
}

.esri-widget button:focus {
  outline: none;
}

.esri-feature__size-container label:focus {
  outline: none;
}

.esri-view-surface:focus::after {
  content: none !important;
}

.esri-popup__main-container {
  border-radius: 10px;
}

.esri-popup__main-container article {
  min-height: 160px;
}

.esri-popup--shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.esri-popup__header-container--button {
  pointer-events: none;
}

.esri-popup__header-container--button:hover {
  background-color: transparent;
}

.esri-popup__button:focus {
  outline: none;
}

.esri-popup__button:hover {
  background-color: transparent;
}

.esri-widget:focus-visible,
.esri-widget:focus,
.esri-widget *:focus-visible,
.esri-widget *:focus {
  outline: none;
}

.esri-search__container .esri-widget--button {
  border-radius: 0 10px 10px 0;
}

.esri-search__container .esri-widget--button:hover {
  background: transparent;
}
.esri-csInfo {
  /*padding: 10px;*/
  /*min-width: 250px;*/
  /*max-width: 350px;*/
  /*border-radius: 10px;*/
  /*transition: 0.5s;*/
  /*opacity: 1;*/
  /*right: 50%;*/
  /*transform: translateX(50%);*/
  /*top: -200px;*/
}
.esri-csHeader {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: rgba(255, 201, 0);
}
.esri-csDescr {
  font-size: 16px;
  max-width: 300px;
}
.esri-csInfo-visible {
  top: 70px;
  opacity: 1;
}

/* arcgis popup styles <<<<*/
.esri-popup__header * {
  padding: 0 8px;
}

.esri-popup__main-container article {
  min-height: 0;
}

.esri-view-width-medium .esri-popup__main-container {
  width: auto;
  display: flex;
}

.esri-popup__main-container {
  width: auto;
  display: flex;
}

.esri-view-width-large .esri-popup__main-container {
  width: auto;
  display: flex;
}

.esri-popup__main-container {
  width: auto;
  display: flex;
}

.esri-view-width-xlarge .esri-popup__main-container {
  width: auto;
  display: flex;
}

.esri-popup__pointer {
  display: none;
}

.esri-popup__content {
  margin: 0;
}
/* >>>> arcgis popup styles */
